import React from "react"
import { TwitterIcon, TwitterShareButton } from "react-share"

import shapeOne from "../../../../images/athena-04-resilience/05-flourishing/shape-one.png"
import windowOne from "../../../../images/athena-04-resilience/05-flourishing/window-one.jpg"

import styles from "../flourishing.module.scss"

const sectionOne = () => {
  return (
    <div>
      <div className={styles.articleSection}>
        <div className={`${styles.column}`}>
          <div className={styles.columnMisc}>
            <img className={styles.shape} src={shapeOne} alt="intro" />
            <h3 className={styles.shareQuote}>
              <span>
                <b>D</b>oing
              </span>
              <span>
                thin<b>g</b>s
              </span>
              <span>
                ou<b>t</b>side
              </span>
              <span>
                <em>o</em>f
              </span>
              <span>
                wo<b>r</b>k
              </span>
              <span>
                <b>m</b>akes
              </span>
              <span>
                u<b>s</b>
              </span>
              <span>
                <b>m</b>ore
              </span>
              <span>
                crea<b>t</b>ive,
              </span>
              <span>better</span>
              <span>
                a<b>t</b>
              </span>
              <span>
                p<b>r</b>oblem-solvin<b>g</b>,
              </span>
              <span>
                a<b>n</b>d
              </span>
              <span>
                b<b>r</b>oadens
              </span>
              <span>
                ou<b>r</b>
              </span>
              <span>
                <b>p</b>erspe<b>c</b>tive
              </span>
              <span>to</span>
              <span>
                ge<b>n</b>erat<b>e</b>
              </span>
              <span>
                n<b>e</b>w
              </span>
              <span>
                i<b>d</b>eas.
              </span>
              <TwitterShareButton
                url={
                  "https://zeusjones.com/athena/articles/flourishing-in-and-out-of-office"
                }
                title="Doing things outside of work makes us more creative, better at problem-solving, and broadens our perspective to generate new ideas."
                className={styles.tweetButton}
                via="zeusjones"
              >
                <span>
                  Tweet <TwitterIcon />
                </span>
              </TwitterShareButton>
            </h3>
          </div>
        </div>
        <div className={styles.columnLine}></div>
        <div className={styles.column}>
          <div className={styles.columnContent}>
            <h4 className={styles.columnIntroText}>
              To learn more about creativity and wellbeing in the workplaces of
              the future, the Athena Jones team talked with culture and
              community strategist{" "}
              <a
                href="https://alicekatter.com/"
                target="_blank"
                rel="noreferrer"
              >
                Alice Katter
              </a>
              , a consultant and thought leader who investigates the blurry
              lines between life, work, and our humanity. Our conversation below
              ranged from remote work to Katter's favorite tools for fostering
              belonging.
            </h4>

            <h5>
              Athena<span> Jones</span>
            </h5>
            <p>
              <strong>
                Having traveled extensively from a young age you have a unique
                perspective on what it means to be “out of office.” When did you
                become interested in the ways people work?
              </strong>
            </p>

            <h5>
              <span>Alice</span> Katter
            </h5>
            <p>
              Maybe because I was lucky to travel a lot from a young age, I was
              always curious about different cultures and living in different
              countries. I started my journey by studying the human psyche and
              got my degree in psychology. At the same time, I always did a lot
              of things outside of work to explore my curiosities (which
              included moving to London, after that, Vienna and later to NYC),
              and bring people together; from organizing team and community
              breakfasts and learning lunches, joining creative networks,
              running creative community events such as Creative Mornings
              Vienna, to organizing Sunday brunch gatherings for more than 100
              people, and even opened up my own coworking space for creatives in
              Vienna.
            </p>
            <p>
              When I went freelance, and gained a lot of flexibility in setting
              up my day-to-day, I was visiting friends and attended conferences
              and events in different cities. And it was at a coworking retreat
              in Cape Town where I realized how this way of living - getting new
              inputs by experiencing or seeing new things, as well as having
              conversations with different people - constantly inspired my work.
              I learned how the balance and interplay between work and 'play' is
              the 'secret sauce' that fuels my creative energy.
            </p>
            <p>
              So I started researching about it, and found evidence for the
              power of “play” and “creative exploration” and that doing things
              outside of work makes us more creative, better at problem-solving,
              and broadens our perspective to generate new ideas. That's when I
              started{" "}
              <a
                href="https://www.getoutofoffice.network/"
                target="_blank"
                rel="noreferrer"
              >
                Out of Office
              </a>{" "}
              as a playground to explore this topic further and actively became
              interested in the ways people work. I got curious about how we can
              design a culture of work that celebrates the values of rest,
              exploration and play, in order to kindle creativity, wonder and
              surprise. I wanted to explore how we can remove the barriers to
              creativity, innovation and serendipity in our own thinking,
              work-lives and workplaces.
            </p>
          </div>
        </div>
      </div>
      <div className={`${styles.imageWindow}`}>
        <img src={windowOne} alt="iphone" />
      </div>
    </div>
  )
}
export default sectionOne
