import React from "react"
import { TwitterIcon, TwitterShareButton } from "react-share"

import styles from "../flourishing.module.scss"

const FullWidthQuoteTwo = () => {
  return (
    <div>
      <div className={`${styles.textWindow}`}>
        <h3 className={styles.shareQuote}>
          <span>By</span>
          <span>
            <b>s</b>harin<b>g</b>
          </span>
          <span>our</span>
          <span className={styles.full}></span>
          <span>
            s<b>t</b>ories
          </span>
          <span>
            wi<b>t</b>h
          </span>
          <span>one</span>
          <span>
            ano<b>t</b>her,
          </span>
          <span className={styles.full}></span>
          <span>we</span>
          <span>
            exp<b>l</b>ore
          </span>
          <span>
            ou<b>r</b>
          </span>
          <span>
            simi<b>l</b>aritie<b>s</b>,
          </span>
          <span>
            un<b>c</b>over
          </span>
          <span>
            diffe<b>r</b>ences,
          </span>
          <span>and</span>
          <span>
            <b>l</b>earn
          </span>
          <span>to</span>
          <span>
            <b>c</b>onne<b>c</b>t
          </span>
          <span>
            <b>o</b>n
          </span>
          <span>a</span>
          <span>
            <b>d</b>eeper
          </span>
          <span>
            le<b>v</b>el.
          </span>
          <TwitterShareButton
            url={
              "https://zeusjones.com/athena/articles/flourishing-in-and-out-of-office"
            }
            title="By sharing our stories with one another, we explore our similarities, uncover differences, and learn to connect on a deeper level."
            className={styles.tweetButton}
            via="zeusjones"
          >
            <span>
              Tweet <TwitterIcon />
            </span>
          </TwitterShareButton>
        </h3>
        <TwitterShareButton
          url={
            "https://zeusjones.com/athena/articles/flourishing-in-and-out-of-office"
          }
          title="By sharing our stories with one another, we explore our similarities, uncover differences, and learn to connect on a deeper level."
          className={`${styles.tweetButton} ${styles.tweetButtonMobile}`}
          via="zeusjones"
        >
          <span>
            Tweet <TwitterIcon />
          </span>
        </TwitterShareButton>
      </div>
    </div>
  )
}
export default FullWidthQuoteTwo
