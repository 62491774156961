import React from "react"
import { TwitterIcon, TwitterShareButton } from "react-share"

import styles from "../flourishing.module.scss"

const FullWidthQuoteOne = () => {
  return (
    <div>
      <div className={`${styles.textWindow}`}>
        <h3 className={styles.shareQuote}>
          <span>We,</span>
          <span>
            a<b>s</b>
          </span>
          <span>
            orga<b>n</b>izatio<b>n</b>s
          </span>
          <span>
            a<b>s</b>
          </span>
          <span>well</span>
          <span>
            a<b>s</b>
          </span>
          <span>
            in<b>d</b>ivid<b>u</b>als,
          </span>
          <span>
            nee<b>d</b>
          </span>
          <span>to</span>
          <span>
            s<b>h</b>ift
          </span>
          <span>our</span>
          <span>
            p<b>e</b>rspe<b>c</b>tive
          </span>
          <span>to</span>
          <span>
            <b>p</b>lay
          </span>
          <span>and</span>
          <span>
            e<b>x</b>plora<b>t</b>ion
          </span>
          <span>not</span>
          <span>
            bein<b>g</b>
          </span>
          <span>
            dist<b>r</b>ac<b>t</b>ions.
          </span>
          <span>
            <b>t</b>hey
          </span>
          <span>
            ar<b>e</b>
          </span>
          <span>
            a so<b>u</b>rce
          </span>
          <span>
            <em>o</em>f
          </span>
          <span>
            e<b>n</b>ergy
          </span>
          <span>and</span>
          <span>
            <b>e</b>mpow<b>e</b>rm<b>e</b>nt.
          </span>
          <TwitterShareButton
            url={
              "https://zeusjones.com/athena/articles/flourishing-in-and-out-of-office"
            }
            title="We, as organizations as well as individuals, need to shift our perspective to play and exploration not being distractions. They are a source of energy and empowerment."
            className={styles.tweetButton}
            via="zeusjones"
          >
            <span>
              Tweet <TwitterIcon />
            </span>
          </TwitterShareButton>
        </h3>
        <TwitterShareButton
          url={
            "https://zeusjones.com/athena/articles/flourishing-in-and-out-of-office"
          }
          title="We, as organizations as well as individuals, need to shift our perspective to play and exploration not being distractions. They are a source of energy and empowerment."
          className={`${styles.tweetButton} ${styles.tweetButtonMobile}`}
          via="zeusjones"
        >
          <span>
            Tweet <TwitterIcon />
          </span>
        </TwitterShareButton>
      </div>
    </div>
  )
}
export default FullWidthQuoteOne
