import React, { useState, useEffect } from "react"
import { sequenceImages, ScrollSequence } from "./heroScroll"
import heroStyles from "./hero.module.scss"

const Intro = () => {
  let [heroFade, setHeroFade] = useState(true)
  useEffect(() => {
    setHeroFade(0)
    let timeoutId = setTimeout(() => {
      setHeroFade(1)
      timeoutId = setTimeout(() => {
        setHeroFade(2)
        timeoutId = setTimeout(() => {
          setHeroFade(3)
          timeoutId = setTimeout(() => {
            setHeroFade(4)
          }, 750)
        }, 500)
      }, 500)
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [])

  let group = index => ({
    style: {
      transition: "opacity .6s ease-in-out",
      opacity: heroFade >= index ? 1 : 0.0125,
    },
  })
  useEffect(() => {
    new ScrollSequence({
      container: ".first-sequence",
      scrollWith: ".first-container",
      images: sequenceImages,
      cover: false,
      playUntil: "scroll-out",
      starts: "in",
    })
    return
  }, [])
  return (
    <div>
      <div className={heroStyles.hero} {...group(2)}>
        <div className={heroStyles.header} {...group(2)}>
          <div className={heroStyles.issue} {...group(3)}>
            <p className="a-h-5">
              Issue 04 / Exploring Joy, Reimagining Resilience
            </p>
          </div>
          <div className={heroStyles.title} {...group(3)}>
            <h1 className="a-h-display">Flourishing In and Out of Office</h1>
            <p className="a-h-5">An Interview with Alice Katter</p>
          </div>
        </div>
      </div>
      <div
        className={`${heroStyles.scrollContainer} first-container`}
        {...group(4)}
      >
        <div className={`${heroStyles.scrollSequenceContainer}`}>
          <div className={`${heroStyles.firstSequence} first-sequence`}></div>
        </div>
      </div>
    </div>
  )
}

export default Intro
